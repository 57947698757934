import * as axios from 'axios';
import Cookies from 'js-cookie';
import history from 'helpers/history';
import { AUTH_TOKEN_KEY, AUTH_REFRESH_TOKEN, USER_ID } from 'variables';

const { REACT_APP_API_URL } = process.env;

const defaultConfig = {
  baseURL: REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
};

export const instance = axios.create(defaultConfig);
export const authenticatedInstance = axios.create(defaultConfig);

export default {
  /**
   * Makes an unauthenticated request
   * @returns {import('axios').AxiosInstance}
   */
  unauthorized() {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        Promise.reject(error);
        return {
          type: 'error',
          code: error.response?.status ?? undefined,
          msg: error.response?.data?.msg ?? undefined,
        };
      }
    )

    return instance;
  },

  /**
   * Makes a authenticated request
   * @returns {import('axios').AxiosInstance}
   */
  authorized() {
    authenticatedInstance.defaults.headers.common.Authorization = (
      `Bearer ${Cookies.get(AUTH_TOKEN_KEY)}`
    );
    authenticatedInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        // Refresh Token
        if (error.response?.status === 401) {
          console.log('Token has expired, trying to renew...', error.response.config);
          if (!Cookies.get(AUTH_REFRESH_TOKEN)) {
            Cookies.remove(AUTH_TOKEN_KEY);
            Cookies.remove(USER_ID);
            window.location.href = '/login';
          }
          try {
            authenticatedInstance.defaults.headers.common.Authorization = `Bearer ${Cookies.get(AUTH_REFRESH_TOKEN)}`;
            const { data } = await authenticatedInstance.get('/usuarios/token');
            if (data) {
              const inThreeHours = new Date(new Date().getTime() + 3 * 3600 * 1000);
              Cookies.set(AUTH_TOKEN_KEY, data.token, {
                expires: inThreeHours,
              });
              authenticatedInstance.defaults.headers.common.Authorization = (`Bearer ${data.token}`);
              console.log('Token sucessfully renewed!');

              try {
                const newConfig = {
                  ...error.response.config,
                };
                newConfig.headers = defaultConfig.headers;
                const newResponse = authenticatedInstance(newConfig);
                return newResponse; // Maybe send Promise.resolve(newResponse);
              } catch (newError) {
                console.error('Error while making request with the renewed token', newError);
                Cookies.remove(AUTH_REFRESH_TOKEN);
                Cookies.remove(AUTH_TOKEN_KEY);
                Cookies.remove(USER_ID);
                window.location.href = '/login';
                return null;
              }
            } else {
              console.error('Refresh token expired');
              Cookies.remove(AUTH_REFRESH_TOKEN);
              Cookies.remove(AUTH_TOKEN_KEY);
              Cookies.remove(USER_ID);
              window.location.href = '/login';
              return null;
            }
          } catch (refreshError) {
            console.error('Refresh token expired');
            Cookies.remove(AUTH_REFRESH_TOKEN);
            Cookies.remove(AUTH_TOKEN_KEY);
            Cookies.remove(USER_ID);
            window.location.href = '/login';
            return null;
          }
          
        } else if (error.response?.status === 422 && error.response?.config.url === '/usuarios/token') {
          console.error('Refresh token expired');
          Cookies.remove(AUTH_REFRESH_TOKEN);
          Cookies.remove(AUTH_TOKEN_KEY);
          Cookies.remove(USER_ID);
          window.location.href = '/login';
          return null;
        }
    
        Promise.reject(error);
        return error;
      },
    );

    return authenticatedInstance;
  },
};

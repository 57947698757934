/**
 * Colors
 */
module.exports = ({
  white: '#FFFFFF',
  black: '#111111',
  primary: '#558B2F',
  primaryLight: '#558B2F',
  primaryDarker: '#595F6E',
  primaryHover: '#467e1d',
  secondary: '#719620',
  bodyBackground: '#F9F9F9',
  success: '#2B965E',
  successAlert: '#52AF61',
  warning: '#AE8F0C',
  error: '#CB6969',
  info: '#0CAEAE',
  text: '#191B1D',
  textDarker: '#2C3E50',
  textLight: '#595F6E',
  textSecondary: '#595F6E',
  darkGray: '#7E8699',
  lightGray: '#C7CCD8',
  superLightGray: '#F7F8FA',
  textGray: '#7E8699',
  lightGrayTransparent: 'rgba(0, 0, 0, 0.1)',
  salmon: '#F5C1A7',

  green: '#46CE8A',
  darkGreen: '#ADC794',
  lightGreen: '#A4BD8C',
  lightGreenTransparent: 'rgba(85, 139, 47, 0.1)',
  purple: '#9997EB',
  darkPurple: '#6A59E8',
  textPurple: '#7E7CC4',
  transparentPurple: '#9997EB26',
  red: '#F26C7E',
  lightRed: '#CB6969',
  darkRed: '#E8596C',
  textRed: '#CC5D6C',
  transparentRed: '#F26C7E26',
  yellow: '#FFCA83',
  lightYellow: '#EFB15D',
  darkYellow: '#EFB15D',
  textYellow: '#C69C63',
  transparentYellow: '#FFCA8326',
  mossGreen: '#A4BD8C',
  darkMossGreen: '#558B2F',
  textMossGreen: '#849870',
  transparentMossGreen: '#A4BD8C26',
  brightGreen: '#46CE8A',
  darkBrightGreen: '#46CE8A',
  textBrightGreen: '#46CE8A',
  transparentBrightGreen: '#46CE8A26',
});

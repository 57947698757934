import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';

const Logout = (props) => (
  <Icon {...props} component={LogoutIcon} />
);

export default Logout;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useAuth } from 'contexts/AuthProvider';
import Cookies from 'js-cookie';
import request from 'api/request';
import { USER_ID } from 'variables';

const UserContext = createContext(null);

function UserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const userID = Cookies.get(USER_ID);
  const { logout, authenticated } = useAuth();
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await request.authorized().get(`/usuarios/${userID}`);
      setUserData(data);
      setLoading(false);
    } catch (error) {
      logout();
    }
  }, [userID, logout]);
  useEffect(() => {
    if (userID) {
      fetchData();
    }
    if (!userID) {
      setLoading(false);
      logout();
    }

    if (authenticated) {
      fetchData();
    }
  }, [userID, logout, authenticated]);

  const value = {
    user: userData,
    loading,
    reload: fetchData,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
export const useUserInformation = () => useContext(UserContext);

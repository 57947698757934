import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';

const Back = (props) => (
  <Icon {...props} component={BackIcon} />
);

export default Back;

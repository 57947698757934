import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

const Settings = (props) => (
  <Icon {...props} component={SettingsIcon} />
);

export default Settings;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

const Calendar = (props) => (
  <Icon {...props} component={CalendarIcon} />
);

export default Calendar;

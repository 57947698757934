import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';

const Wallet = (props) => (
  <Icon {...props} component={WalletIcon} />
);

export default Wallet;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as UserIcon } from 'assets/icons/user.svg';

const User = (props) => (
  <Icon {...props} component={UserIcon} />
);

export default User;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as NormalizationIcon } from 'assets/icons/normalizacao.svg';

const Normalization = (props) => (
  <Icon {...props} component={NormalizationIcon} />
);

export default Normalization;

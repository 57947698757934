import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as PoleIcon } from 'assets/icons/postes.svg';

const Pole = (props) => (
  <Icon {...props} component={PoleIcon} />
);

export default Pole;

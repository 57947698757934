const { NODE_ENV } = process.env;

export const AUTH_TOKEN_KEY = '_logo_auth';

export const AUTH_REFRESH_TOKEN = '_logo_session';

export const USER_ID = '_user_id';

export const FRONT_URL = NODE_ENV === 'production' ? 'https://logo.loomi.com.br/' : 'https://logoverde.netlify.app/';

export const ALERT_SERVER_ERROR = {
  type: 'error',
  message: 'Ops! Houve um problema com a requisição.',
};

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as PurgeIcon } from 'assets/icons/purge.svg';

const Purge = (props) => (
  <Icon {...props} component={PurgeIcon} />
);

export default Purge;

import React, {
  createContext,
  useContext,
  useState,
} from 'react';

import Modal, { CloseIcon } from 'components/Modal';

const ModalContext = createContext({
  modal: null,
  visible: false,
});

function ModalProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState(null);
  const [content, setContent] = useState(false);
  const [result, setResult] = useState();

  const modal = (newContent, newParams) => {
    if (newContent) {
      setContent(newContent);
    }

    if (newParams) {
      setParams(newParams);
    }

    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <ModalContext.Provider
      value={{ visible, modal, closeModal, setResult }}
    >
      {children}

      <Modal
        visible={visible}
        centered
        onCancel={closeModal}
        closeIcon={<CloseIcon />}
        okText="Confirmar"
        okButtonProps={{
          shape: 'round',
        }}
        cancelText="Cancelar"
        cancelButtonProps={{
          shape: 'round',
          type: 'primary',
          className: 'modal-button-cancel',
        }}
        {...params}
        onOk={() => {
          params.onOk(closeModal, result);
        }}
      >
        {content}
      </Modal>
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);

export default ModalProvider;

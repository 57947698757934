import styled from 'styled-components/macro';
import { Close } from 'components/icons';

import colors from 'styles/colors';

export const StyledClose = styled(Close)`
  font-size: 10px;

  path {
    fill: ${colors.text};
  }
`;

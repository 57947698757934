import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';

const Building = (props) => (
  <Icon {...props} component={BuildingIcon} />
);

export default Building;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as EquipmentsIcon } from 'assets/icons/equipamentos.svg';

const Equipments = (props) => (
  <Icon {...props} component={EquipmentsIcon} />
);

export default Equipments;

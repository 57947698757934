import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as CableReelIcon } from 'assets/icons/cable-reel.svg';

const CableReel = (props) => (
  <Icon {...props} component={CableReelIcon} />
);

export default CableReel;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as LayersIcon } from 'assets/icons/layers.svg';

const Layers = (props) => (
  <Icon {...props} component={LayersIcon} />
);

export default Layers;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';

const More = (props) => (
  <Icon {...props} component={MoreIcon} />
);

export default More;

import AntIcon from '@ant-design/icons';
import styled from 'styled-components';
import {
  fontSize,
  space,
  color,
  position,
} from 'styled-system';

const Icon = styled(AntIcon)(
  fontSize,
  space,
  color,
  position,
);

export default Icon;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as DeactivationIcon } from 'assets/icons/desativacao.svg';

const Deactivation = (props) => (
  <Icon {...props} component={DeactivationIcon} />
);

export default Deactivation;

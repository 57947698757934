import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

const Check = (props) => (
  <Icon {...props} component={CheckIcon} />
);

export default Check;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

const Filter = (props) => (
  <Icon {...props} component={FilterIcon} />
);

export default Filter;

import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as ImagesIcon } from 'assets/icons/images.svg';

const Images = (props) => (
  <Icon {...props} component={ImagesIcon} />
);

export default Images;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import theme from 'styles/theme';

import 'dayjs/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';

import AuthProvider from './AuthProvider';
import UserProvider from './UserProvider';
import ModalProvider from './ModalProvider';
import ToastProvider from './ToastProvider';

function GlobalProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={locale}>
        <AuthProvider>
          <UserProvider>
            <ToastProvider>
              <ModalProvider>
                {children}
              </ModalProvider>
            </ToastProvider>
          </UserProvider>
        </AuthProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export const withGlobalProvider = (Component) => (
  (props) => (
    <GlobalProvider>
      <Component {...props} />
    </GlobalProvider>
  )
);

export default GlobalProvider;

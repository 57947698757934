import React from 'react';
import { Modal as AntdModal } from 'antd';

import {
  StyledClose,
} from './Modal.style';

function Modal(props) {
  return (
    <AntdModal
      {...props}
    />
  );
}

export const CloseIcon = StyledClose;
export default Modal;

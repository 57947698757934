import React, {
  useState, useMemo, useEffect, useContext,
} from 'react';
import Cookies from 'js-cookie';
import { AUTH_REFRESH_TOKEN, AUTH_TOKEN_KEY, USER_ID } from 'variables';
import { AuthAPI } from 'api';

const initialValues = {
  authenticated: null,
  token: null,
  user_id: null,
};

const AuthContext = React.createContext(initialValues);

function AuthProvider(props) {
  /** state */
  const [authenticated, setAuthenticated] = useState();
  const [checkedRemember, setCheckedRemember] = useState(false);
  const [token, setToken] = useState();
  const [authorization, setAuthorization] = useState(null);

  useEffect(() => {
    const userToken = Cookies.get(AUTH_TOKEN_KEY);
    setToken(userToken);
    setAuthenticated(Boolean(userToken));
  }, []);

  useEffect(() => {
    const inThreeHours = new Date(new Date().getTime() + 3 * 3600 * 1000);
    if (authorization) {
      if (checkedRemember) {
        Cookies.set(AUTH_REFRESH_TOKEN, authorization.refreshToken, {
          expires: 15,
        });
      }
      Cookies.set(AUTH_TOKEN_KEY, authorization.token, {
        expires: inThreeHours,
      });
      Cookies.set(USER_ID, authorization.id);
      setToken(authorization.token);
      setAuthenticated(true);
    }
  }, [authorization]);

  const login = async (params, remember) => {
    setCheckedRemember(remember);
    const res = await AuthAPI.login(params);
    setAuthorization(res.data);
    return res;
  };

  /** logout */
  const logout = async () => {
    Cookies.remove(USER_ID);
    Cookies.remove(AUTH_TOKEN_KEY);
    Cookies.remove(AUTH_REFRESH_TOKEN);

    setAuthenticated(false);
  };

  /** provider */
  const value = useMemo(
    () => ({ token, authenticated }),
    [token, authenticated],
  );

  return (
    <AuthContext.Provider
      value={{ ...value, login, logout }}
      {...props}
    />
  );
}

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;

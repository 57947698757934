import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';

const Chevron = (props) => (
  <Icon {...props} component={ChevronIcon} />
);

export default Chevron;

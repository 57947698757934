import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as NetworkIcon } from 'assets/icons/rede.svg';

const Network = (props) => (
  <Icon {...props} component={NetworkIcon} />
);

export default Network;
